import React, {useEffect, useState} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import httpRequest from '../api/httpRequest';
import Styles from './portfolioTimer.module.scss';
import CustomTooltip from '../common/Tooltip/CustomTooltip';

const pad = num => {
  num = num.toString();
  if (num.length < 2) {
    return '0' + num;
  }
  return num;
};

const PortfolioTimer = ({children}) => {
  const [timeData, setTimeData] = useState(null);
  const [timeFinished, setTimeFinished] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});

  useEffect(() => {
    let timeout = null;
    let url = process.env.REACT_APP_BACKEND_URL_FORM_API;
    url = url.replace('/v1/', '/check-reset-timer');
    httpRequest.get(url).then(res => {
      let {hour, minute, seconds} = res;
      setInterval(() => {
        if (seconds === 0) {
          seconds = 59;
          if (minute === 0) {
            minute = 59;
            if (hour > 1) {
              hour--;
            }
          } else {
            minute--;
          }
        } else {
          seconds--;
        }
        if (hour + minute + seconds === 0) {
          setTimeFinished(true);
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        }
        setTimeData({hour, minute, seconds});
      }, 1000);
      setTimeData(res);
    });

    return () => {
      clearInterval(timeout);
    };
  }, []);

  const handleMouseDown = e => {
    // Calculate the offset between the mouse click and the top-left corner of the div
    const offsetX = e.clientX - position.x;
    const offsetY = e.clientY - position.y;
    const targetElement = e.target;

    // Add event listeners for mouse move and mouse up events
    const handleMouseMove = e => {
      // Calculate the new position of the div based on the mouse coordinates and offset
      targetElement.style.cursor = 'grabbing';
      const x = e.clientX - offsetX;
      const y = e.clientY - offsetY;

      // Set the new position
      setPosition({x, y});
    };

    const handleMouseUp = () => {
      // Remove the event listeners for mouse move and mouse up events
      targetElement.style.cursor = 'grab';
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  if (timeFinished) {
    return (
      <Box className={Styles.finishedRoot}>
        <Typography variant="h4">Time finished</Typography>
        <Typography>Redirecting you to list page...</Typography>
      </Box>
    );
  }

  return (
    <>
      {timeData && (
        <Box
          onMouseDown={handleMouseDown}
          sx={{transform: `translate(${position.x}px, ${position.y}px)`}}
          className={Styles.root}
        >
          <Box className={Styles.textContainer}>
            <Stack flexDirection="row" alignItems="center">
              <Typography className={Styles.titleText}>All data will be reset within</Typography>{' '}
              <CustomTooltip
                placement="top"
                arrow
                title="This is made for my portfolio, so I delete all the data at intervals."
              >
                <HelpIcon fontSize={'small'} />
              </CustomTooltip>
            </Stack>
            <Typography className={Styles.timeText}>
              {pad(timeData.hour)}:{pad(timeData.minute)}:{pad(timeData.seconds)}
            </Typography>
          </Box>
        </Box>
      )}
      {children}
    </>
  );
};

export default PortfolioTimer;
