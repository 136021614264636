import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import InboxView from './InboxView/InboxView';
import TableView from './TableView/TableView';
import SubmissionHeader from './SubmissionHeader';
import SeoSettings from '../../common/SeoSettings';
import {selectFormSubmissionPageView} from '../../state/features/formSubmission/formSubmissionSelector';
import {resetFormSubmissionState} from '../../state/features/formSubmission/formSubmissionSlice';
import {getTags} from '../../state/features/tags/tagsSlice';
import {
  FORM_SUBMISSION_PAGE_VIEW_GRID,
  FORM_SUBMISSION_PAGE_VIEW_TABLE
} from '../../helpers/constant/formSubmissionConstant';

const Submission = () => {
  const dispatch = useDispatch();
  const pageView = useSelector(selectFormSubmissionPageView);

  useEffect(() => {
    dispatch(getTags());
    return () => dispatch(resetFormSubmissionState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className="inboxSubmissionWrapper"
      sx={{
        padding: '30px',
        height: '100vh',
        overflow: 'hidden',
        '@media (max-width:992px)': {width: 'calc(100vw - 40px)'}
      }}
    >
      <SeoSettings
        title="Form builder - Submissions"
        description="Form builder Submissions"
        keywords="test, test1"
      />
      <SubmissionHeader />

      {pageView === FORM_SUBMISSION_PAGE_VIEW_GRID && <InboxView />}
      {pageView === FORM_SUBMISSION_PAGE_VIEW_TABLE && <TableView />}
    </Box>
  );
};

export default Submission;
