import React from 'react';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';
import InboxCell from './InboxCell';
// import StatusCell from '../TableView/StatusCell';
import InboxViewBySubmissionType from './InboxViewBySubmissionType';
import {selectFormSubmissionTableHeaders} from '../../../state/features/formSubmission/formSubmissionSelector';

const getIdValuePair = submissionList => {
  let resultObj = {};
  for (let submissionData of submissionList) {
    resultObj[submissionData.id] = submissionData;
  }

  return resultObj;
};

const InboxBodyContent = ({submission = []}) => {
  const {
    submission: {submission: submissionDataList = [], agencyId = ''} = {},
    // comments,
    createdAt
  } = submission || {};
  const tableHeader = useSelector(selectFormSubmissionTableHeaders);
  const submissionObject = getIdValuePair(submissionDataList);

  const formatUpdatedAt = createdAt ? format(new Date(createdAt), 'dd MMM yyyy') : '';
  return (
    <div>
      <InboxCell rowOne={'Submission Date'} rowTwo={formatUpdatedAt} />
      {/* <InboxCell rowOne={'Status'} rowTwo={<StatusCell comments={comments} />} /> */}
      {tableHeader?.length > 0 &&
        tableHeader.map((column, index) => (
          <InboxCell
            key={index}
            rowOne={column.label}
            rowTwo={
              <InboxViewBySubmissionType column={submissionObject[column.id]} agencyId={agencyId} />
            }
          />
        ))}
    </div>
  );
};

export default InboxBodyContent;
