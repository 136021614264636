export const CONTACT_FIELDS_MAP = {
  first_name: {label: 'First Name', type: 'text'},
  last_name: {label: 'Last Name', type: 'text'},
  number: {label: 'Phone Number', type: 'phone'},
  email: {label: 'Email', type: 'email'},
  country: {label: 'Country', type: 'text'},
  address: {label: 'Address', type: 'text'},
  state: {label: 'State', type: 'text'},
  city: {label: 'City', type: 'text'},
  zip: {label: 'Zip', type: 'text'},
  url: {label: 'Url', type: 'url'},
  deal_value: {label: 'Deal Value', type: 'number'},
  birth_date: {label: 'Birth Date', type: 'date'},
  anniversary_date: {label: 'Anniversary Date', type: 'date'},
  company_name: {label: 'Company Name', type: 'text'}
};

export const USER_PERSONALIZED_MAP = [
  {title: 'My virtual number', value: '[[virtual_number]]'},
  {title: 'My Company', value: '[[company]]'},
  {title: 'My Name', value: '[[my_name]]'},
  {title: 'My First Name', value: '[[my_first_name]]'},
  {title: 'My Last Name', value: '[[my_last_name]]'},
  {title: 'My Phone', value: '[[my_phone]]'},
  {title: 'My Signature', value: '[[my_signature]]'},
  {title: 'My Appointment URL', value: '[[appointment_url]]'},
  {title: 'My Email', value: '[[my_email]]'}
];

export const DROPDOWN_BOTTOM_SETUP = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  getContentAnchorEl: null
};

export const MODAL_DEFAULT_OPTIONS = {
  currentPage: '',
  prevIndex: '',
  prevFieldType: '',
  newFieldType: '',
  newFieldName: '',
  newComponent: '',
  newConfiguration: ''
};

export const PROGRESSBAR_DEFAULT_OPTIONS = {
  backgroundColor: '#3C7EF3',
  color: '#FFFFFF'
};

const CoreConstants = {
  REDIRECT_AFTER_SUBMIT: 1,
  MESSAGE_AFTER_SUBMIT: 2,

  STATUS_ENABLE: 1,
  STATUS_DISABLE: 0,
  STATUS_INVISIBLE: 2,

  LANDING_PAGE_BG_COLOR: 1,
  LANDING_PAGE_BG_IMAGE: 2,

  VALUE_NO: 0,
  VALUE_YES: 1,

  FORM_ENTRY: {
    STATUS_CONTACT_INSERTED: 1,
    STATUS_DUPLICATE_CONTACT: 2,
    STATUS_ERROR: 3,

    CAMPAIGN_STATUS_SUCCESS: 1,
    CAMPAIGN_STATUS_ERROR: 0,

    STAGE_STATUS_SUCCESS: 1,
    STAGE_STATUS_ERROR: 0
  }
};

export const FORM_TYPE = {
  CLASSIC: 'classic',
  CARD: 'card'
};

export const BUILDER_OPTIONS = {
  BUILDER: 1,
  FORM_SETTING: 2,
  QUICK_SHARE: 3,
  VIEW_SUBMISSION: 4,
  PREVIEW: 5
};

export const SCREEN_OPTIONS = {
  DESKTOP: 1,
  TAB: 2,
  MOBILE: 3
};

export const FORM_SETTING_ID = {
  QUICK_SHARE: 1,
  EMBED: 2,
  EMAIL: 3,
  FORM_SETTING: 4,
  THANK_YOU: 5,
  CONDITIONS: 6,
  SEO_SETTING: 7,
  MAPPING: 8,
  HEADER_SETTING: 9,
  FOOTER_SETTING: 10
};

export const STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  FAILED: 3
};

export const MODULE_NAMES = {
  FORM: 'FORM',
  ADMIN_FORM_TEMPLATE: 'ADMIN_FORM_TEMPLATE'
};

export const TRASH_TYPES = {
  HARD: 'HARD'
};

export const FORM_INPUT_TYPES = {
  TEXT: 'text',
  PHONE: 'phone',
  EMAIL: 'email',
  MULTILINE: 'textarea',
  URL: 'url',
  NUMBER: 'number',
  DATE: 'date',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  RADIO: 'radio'
};

export default CoreConstants;
