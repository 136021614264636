import React from 'react';
import {Box} from '@mui/material';
import AddMediaUrlTab from './AddMediaUrlTab';
// import AddMediaIconTab from "./AddMediaIconTab";
import AddMediaImageTab from './AddMediaImageTab';
import AddMediaUploadTab from './AddMediaUploadTab';
// import AddMediaMyImagesTab from './AddMediaMyImagesTab';
import {AddMediaWrapper} from './AddMediaStyles';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const AddImageIconContent = ({onAddImageIcon, pexelsView = false}) => {
  const handleTabChange = value => {
    console.log(value);
  };

  return (
    <Box sx={{p: 3, pt: 1, bgcolor: 'background.paper', height: '100%'}}>
      <CustomTabs onChange={handleTabChange}>
        {pexelsView && (
          <CustomTab title="Images">
            <AddMediaWrapper>
              <AddMediaImageTab onAddImageIcon={onAddImageIcon} />
            </AddMediaWrapper>
          </CustomTab>
        )}
        {/* <CustomTab title="Icons">
         <AddMediaWrapper>
           <AddMediaIconTab onAddImageIcon={onAddImageIcon} />
         </AddMediaWrapper>
        </CustomTab> */}
        <CustomTab title="Upload">
          <AddMediaWrapper>
            <AddMediaUploadTab onAddImageIcon={onAddImageIcon} />
          </AddMediaWrapper>
        </CustomTab>
        {/* <CustomTab title="My Images">
          <AddMediaWrapper>
            <AddMediaMyImagesTab onAddImageIcon={onAddImageIcon} />
          </AddMediaWrapper>
        </CustomTab> */}
        <CustomTab title="URL">
          <AddMediaWrapper>
            <AddMediaUrlTab onAddImageIcon={onAddImageIcon} />
          </AddMediaWrapper>
        </CustomTab>
      </CustomTabs>
    </Box>
  );
};

export default AddImageIconContent;
