import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Box, IconButton, Typography} from '@mui/material';
import {Delete} from '@mui/icons-material';
import InboxSubmissionSearch from '../InboxView/InboxSubmissionSearch';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
// import AddToCampaign from '../AddToCampaign/AddToCampaign';
import ExportFormContent from '../../FormList/ExportFormContent';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import DownloadIcon from '../../../common/Icons/DownloadIcon';
// import BasicDrawer from '../../../common/Drawer/BasicDrawer';
// import CoreButton from '../../../common/Button/CoreButton';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import useBoolean from '../../../hooks/useBoolean';
import {FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION} from '../../../helpers/constant/formSubmissionConstant';
import {inboxFormDelete} from '../../../state/features/formSubmission/formSubmissionSlice';
import {
  // selectSelectedContactIds,
  selectSelectedInboxFormIds
} from '../../../state/features/formSubmission/formSubmissionSelector';

const TableToolbar = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const selectedSubmissionIds = useSelector(selectSelectedInboxFormIds);
  // const selectedContactIds = useSelector(selectSelectedContactIds);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  // const [isDrawerOpen, setDrawerOpen] = useState(false);
  const {value: openExportModal, toggle: toggleExportModal} = useBoolean(false);

  // const toggleDrawerOpen = () => setDrawerOpen(prevState => !prevState);

  const onOpenDeleteModal = () => setOpenDeleteModal(true);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDelete = () => {
    onOpenDeleteModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(inboxFormDelete({ids: selectedSubmissionIds}));
    onCloseDeleteModal();
  };

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', p: 2}}>
      {selectedSubmissionIds.length > 0 ? (
        <Typography variant="subtitle1" color={'text.secondary'} sx={{ml: 0}}>
          {`Selected ${selectedSubmissionIds.length} 
          ${selectedSubmissionIds.length === 1 ? 'row' : 'rows'}`}
        </Typography>
      ) : (
        <InboxSubmissionSearch />
      )}

      {selectedSubmissionIds.length > 0 && (
        <>
          <Box sx={{display: 'flex', alignItems: 'center', marginRight: '4px'}}>
            <CustomTooltip title="Export" arrow placement="bottom">
              <IconButton onClick={toggleExportModal}>
                <DownloadIcon color={'action'} />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip title="Delete" arrow placement="bottom">
              <IconButton onClick={handleDelete}>
                <Delete color={'action'} />
              </IconButton>
            </CustomTooltip>

            {/* <CoreButton
              size="small"
              variant="contained"
              sx={{minWidth: '142px', padding: '4px 8px', ml: '8px'}}
              color="primary"
              onClick={toggleDrawerOpen}
              disabled={selectedContactIds?.length === 0}
            >
              Add to Campaign
            </CoreButton> */}
          </Box>
        </>
      )}

      {/* <BasicDrawer open={isDrawerOpen} title={'Add to Campaign'} toggleDrawer={toggleDrawerOpen}>
        <AddToCampaign onClose={toggleDrawerOpen} isBulkAction={true} />
      </BasicDrawer> */}

      <DeleteWarningModal
        title={'Delete Submission'}
        warningSubtitle={'Are you sure you want to delete selected submission(s)?'}
        warningContent={`Selected ${selectedSubmissionIds.length} ${
          selectedSubmissionIds.length > 1 ? 'submissions' : 'submission'
        }  will be deleted permanently.This action cannot be undone.`}
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete Forever'}
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />

      <BasicModalWithHeader
        modalTitle={'Export Submissions'}
        open={openExportModal}
        onClose={toggleExportModal}
      >
        <ExportFormContent
          onClose={toggleExportModal}
          formId={formId}
          module={FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION}
          submissionIds={selectedSubmissionIds}
        />
      </BasicModalWithHeader>
    </Box>
  );
};

export default TableToolbar;
