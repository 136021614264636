import React from 'react';
import {BrowserRouter, Route, Router, Switch, Redirect} from 'react-router-dom';
import BuilderIndex from '../components/Builder/BuilderIndex';
import FormListPage from '../components/FormList/FormListPage';
import PublicPage from '../components/PublicView/PublicPage';
import Submission from '../components/Submission/Submission';
import AdminTemplate from '../components/AdminTemplate/AdminTemplate';
import FilesPage from '../components/Files/FilesPage';
import AdminPrivateLayout from '../components/Layouts/AdminPrivateLayout';
import FormStatistics from '../components/Statistics/FormStatistics';
import ErrorPage from '../common/ErrorPage';
import Reports from '../components/Reports/Reports';

const Routes = ({history}) => {
  const RouteComponent = history ? Router : BrowserRouter;

  return (
    <RouteComponent history={history}>
      <div>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/fbuilder/forms" />} />
          <Route exact path="/fbuilder/forms" component={FormListPage} />
          <Route exact path="/fbuilder/statistics/:formId" component={FormStatistics} />
          <Route exact path="/fbuilder/submission/:formId" component={Submission} />
          <Route exact path="/fbuilder/form/:formId" component={BuilderIndex} />
          <Route exact path="/fbuilder/public/:base64Key" component={PublicPage} />
          <Route
            exact
            path="/fbuilder/admin"
            render={() => (
              <AdminPrivateLayout>
                <AdminTemplate />
              </AdminPrivateLayout>
            )}
          />
          <Route
            exact
            path="/fbuilder/admin/template/:formId"
            render={() => (
              <AdminPrivateLayout>
                <BuilderIndex />
              </AdminPrivateLayout>
            )}
          />
          <Route
            exact
            path="/fbuilder/report"
            render={() => (
              <AdminPrivateLayout>
                <Reports />
              </AdminPrivateLayout>
            )}
          />
          <Route exact path="/fbuilder/files" component={FilesPage} />
          <Route exact component={ErrorPage} />
        </Switch>
      </div>
    </RouteComponent>
  );
};

export default Routes;
