import React from 'react';
import Routes from './routes/Routes';
import {createTheme, ThemeProvider} from '@mui/material';
import Snackbars from './common/Snackbars/Snackbars';
import {
  lightModePalette,
  myBreakpoints,
  typographyProperties
} from './helpers/constant/themeConstant';
import PortfolioTimer from './portfolioTimer/PortfolioTimer';
// import {setCookie} from './helpers/Cookie';

function App({history}) {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: lightModePalette,
        typography: typographyProperties,
        breakpoints: myBreakpoints
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <PortfolioTimer>
        <Snackbars />
        <Routes history={history} />
      </PortfolioTimer>
    </ThemeProvider>
  );
}

export default App;
